@font-face {
  font-family: AntDesign;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/AntDesign.ttf) format("truetype");
}
@font-face {
  font-family: Entypo;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Entypo.ttf) format("truetype");
}
@font-face {
  font-family: EvilIcons;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/EvilIcons.ttf) format("truetype");
}
@font-face {
  font-family: Feather;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Feather.ttf) format("truetype");
}
@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/FontAwesome.ttf) format("truetype");
}
@font-face {
  font-family: FontAwesome5;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/FontAwesome5.ttf) format("truetype");
}
@font-face {
  font-family: FontAwesome5Brands;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/FontAwesome5_Brands.ttf) format("truetype");
}
@font-face {
  font-family: Foundation;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Foundation.ttf) format("truetype");
}
@font-face {
  font-family: Ionicons;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Ionicons.ttf) format("truetype");
}
@font-face {
  font-family: MaterialCommunityIcons;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MaterialCommunityIcons.ttf) format("truetype");
}
@font-face {
  font-family: MaterialIcons;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MaterialIcons.ttf) format("truetype");
}
@font-face {
  font-family: Octicons;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Octicons.ttf) format("truetype");
}
@font-face {
  font-family: SimpleLineIcons;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/SimpleLineIcons.ttf) format("truetype");
}
@font-face {
  font-family: Zocial;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Zocial.ttf) format("truetype");
}

.App {
  text-align: center;
}

.App-body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.pageContainer {
  display: block;
  flex-direction: column;
  width: 800px;
  margin-top: auto;
  margin-left: auto;
  margin-bottom: 50px;
  margin-right: auto;
  color: #636466;
}

.header {
  color: #636466;
  display: flex;
  height: 80px;
  background-color: #fdb913;
  align-items: center;
  justify-content: space-between;
}

.headerLogo {
  padding: 20px;
}

.headerLogo:hover {
  cursor: pointer;
}

.headerButtonsContainer {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  align-items: center;
}

.logoutbtncontainer {
  border-radius: 4px;
  overflow: hidden;
  padding: 4px;
  height: 28px;
}

.logoutbtncontainer:hover {
  background-color: rgb(255, 123, 0);
  cursor: pointer;
}

.menuItemLinkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px 4px 12px;
  border-radius: 28px;
  overflow: hidden;

  height: 28px;
  background-color: #636466;
  margin-right: 20px;
  min-width: 80px;
  justify-content: center;
  border-color: aliceblue;
  border-width: 10px;
}

.menuButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menuItemLinkContainer:hover {
  background-color: rgb(255, 123, 0);
  cursor: pointer;
}

.menuSectionLinkContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}

.menuContainer {
  display: "flex";
  background-color: orange;
  align-items: center;
  height: 40;
}

.innerMenuContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 40px;
}

.menuSectionContainer {
  z-index: 1;
}

.LeftMenuItems {
  display: flex;
}

.menuItemLink {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: white;
  align-items: center;
  z-index: 1;
}

.menuItemLinkSelected {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: white;
  align-items: center;
  z-index: 1;
}

.menuItemIcon {
  width: 40;
}

.menuDropDownLinksContainer {
  float: left;
  white-space: nowrap;
  background-color: orange;
}

.boxContainer {
  width: 200px;
  min-height: 200px;
  border: 1px solid #636466 !important;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 4px;
}

.boxContainer ul {
  list-style-type: none;
  list-style-position: outside;
  padding: 0;
}

.boxContainer li {
  padding: 5px 0px;
}

.trendBoxesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.trendboxContainer {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 200px;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 5px 5px 5px #6364665b;
  padding: 20px;
}

.trendboxText {
  font-weight: bold;
}

.trendboxDataContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.trendboxNumber {
  font-size: 60px;
  padding-right: 5px;
}

.satisfactionPieContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sectionGlobalStatsContainer {
  width: 200px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  box-shadow: 5px 5px 5px #6364665b;
  margin-bottom: 20px;
}

.evaluationContainer {
  padding-bottom: 8px;
}

.smallTitle {
  font-weight: bold;
  text-align: center;
}

.statsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.gallery {
  padding: 0.5vw;
  font-size: 0;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  -ms-flex-direction: column;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  display: -webkit-box;
  display: flex;
  height: 180px;
  overflow: hidden;
}

.gallery div {
  -webkit-box-flex: auto;
  -ms-flex: auto;
  flex: auto;
  width: 120px;
  margin: 0.5vw;
}

.gallery div img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

a {
  color: #636466;
  text-decoration: none;
}

a:hover {
  color: orange;
  text-decoration: underline;
}

ul.redLine li {
  position: relative; /* so that pseudoelements are positioned relatively to their "li"s*/
  /* use padding-bottom instead of margin-bottom.*/
  margin-bottom: 0; /* This overrides previously specified margin-bottom */
  padding-bottom: 2.5em;
  min-height: 120px;
  list-style-type: none;
}

ul.redLine li:after {
  /* bullets */
  content: url("http://upload.wikimedia.org/wikipedia/commons/thumb/3/30/RedDisc.svg/20px-RedDisc.svg.png");
  position: absolute;
  left: -26px; /*adjust manually*/
  top: 0px;
}

ul.redLine li:before {
  /* lines */
  content: "";
  position: absolute;
  left: -16px; /* adjust manually */
  border-left: 1px dotted red;
  height: 100%;
  width: 1px;
}

ul.redLine li:first-child:before {
  /* first li's line */
  top: 6px; /* moves the line down so that it disappears under the bullet. Adjust manually */
}

ul.redLine li:last-child:before {
  /* last li's line */
  height: 126px; /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
}

.redLineContainer {
  display: flex;
  flex-direction: row;
  box-shadow: 5px 5px 5px #6364665b;
  padding: 8px;
}

.redLineImage {
  object-fit: cover;
  width: 120px;
  height: 120px;
  padding-right: 16px;
}

.redLineMilestone {
  font-size: smaller;
}

.redLinePedagogicalArea {
  text-decoration: underline;
}

.redLineTitle {
  font-weight: bold;
}

.redLineComment {
  font-size: smaller;
  font-style: italic;
  padding-top: 4px;
}

@media screen and (max-width: 900px) {
  .pageContainer {
    width: initial;
    padding-left: 40px;
    padding-right: 40px;
  }

  .menuContainer {
    flex-direction: column;
    width: 100%;
    height: initial;
    align-items: initial;
  }

  .menuButtonsContainer {
    flex-direction: column;
  }

  .innerMenuContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    height: initial;
  }

  .pageContainer {
    width: initial;
    padding-left: 40px;
    padding-right: 40px;
  }

  .header {
    height: initial;
    width: 100%;
    flex-direction: column;
  }

  .headerButtonsContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: initial;
    width: 100%;
  }

  .logoutbtncontainer {
    width: 100%;
    justify-content: center;
  }

  .menuItemLinkContainer {
    margin-right: initial;
    width: 100%;
    background-color: initial;
    border-radius: initial;
  }

  .LeftMenuItems {
    flex-direction: column;
  }
}

/* print styles */
@media print {
  @page {
    margin: 3cm 2cm 3cm 2cm;
  }
  body {
    margin: 0;
    color: #000;
    background-color: #fff;
  }
  /* .header {
    display: none;
  } */
  .printHidden {
    display: none;
  }
  .page-break-before {
    page-break-before: always;
  }
  .page-break-after {
    page-break-after: always;
  }
  .page-break-after-avoid {
    page-break-after: avoid;
  }
  .page-break-inside-avoid {
    page-break-inside: avoid;
  }
}
